import React, { Fragment, useEffect, useState } from "react";
import {
  comparatorForDate,
  CustomSnackbar,
  formatDateForGrid,
  GridLoadingSpinner,
  handleErrorResponse,
  ModalErrors,
} from "@genrecp/g2clientportal-common";

import { AgGridReact } from "ag-grid-react";
import { ICellRendererParams } from "ag-grid-community";
import { Stack } from "@mui/material";

import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridClasses } from "@genre/g2common-theme";
import { ColDef } from "ag-grid-community";
import { EditChildRenderer } from "@genrecp/g2clientportal-common";
import { Grow } from "@mui/material";
import { delegatedAxios } from "../../../../../../services/axios";
import { CashTrackerProps, ErrorReasons } from "../../../CashTrackerUtils";
import { QAGridType } from "../utils";

interface Props extends CashTrackerProps {
  errorReasons: ErrorReasons[];
}

function QALog({ request, dispatch, errorReasons }: Props) {
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !request.QA.reloadQA && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.QA.reloadQA]);

  function getData() {
    setLoading(true);
    delegatedAxios
      .get(
        `/CashTracker/${request.cashTrackerMasterFields.cashTrackerID}/CashTrackerQuality/GetByQualityTypeId/1`
      )
      .then((res: any) => {
        setLoading(false);

        const { status, data } = res || {};
        if (status === 200) {
          setRowData(data || []);
        } else setRowData([]);
      })
      .catch((e: any) => {
        setLoading(false);
        setRowData([]);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
  };

  const colDefs: ColDef[] = [
    {
      field: "pass",
      colId: "pass",
      headerName: "Grade",
      filter: false,
      cellRenderer: "agGroupCellRenderer",
      valueGetter: function (params: any) {
        return params.data.pass ? "Pass" : "Fail";
      },
    },
    {
      field: "createdDate",
      colId: "createdDate",
      flex: 1,
      filter: "agDateColumnFilter",
      valueFormatter: function (params: any) {
        return formatDateForGrid(params.data.createdDate);
      },
      filterParams: {
        filterOptions: ["equals"],
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue, true);
        },
        suppressAndOrCondition: true,
      },
    },
    {
      field: "createdByUserLoginId",
      colId: "createdByUserLoginId",
      headerName: "Recorded By",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
    },
    {
      field: "qaErrorResonTypeIDs",
      colId: "qaErrorResonTypeIDs",
      headerName: "QA Error Reasons",
      filter: false,
      autoHeight: true,
      flex: 1.25,
      cellRenderer: (params: ICellRendererParams<QAGridType>) => {
        const { qaErrorResonTypeIDs } = params?.data || {};

        if (!qaErrorResonTypeIDs) {
          return null;
        } else {
          return (
            <Stack width={"100%"} display={"flex"} spacing={-1} flexDirection={"column"}>
              {qaErrorResonTypeIDs.map((qaErrorResonTypeID: number) => (
                <span>{ errorReasons.find(
                  (item) => item.qaErrorReasonTypeID === qaErrorResonTypeID
                )?.qaErrorReasonTypeText || ""}</span>
              ))}
            </Stack>
          );
        }
      },
    },
  ];

  const frameworkComponents = {
    EditChildRenderer: EditChildRenderer,
  };

  return (
    <Fragment>
      <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
      <GridLoadingSpinner loading={loading}>
        <Grow in={true} style={{ transitionDelay: "100ms" }}>
          <div
            className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
            style={{ width: "100%", height: window.innerHeight - 250 }}
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              rowData={rowData}
              columnDefs={colDefs}
              pagination={true}
              cacheBlockSize={10}
              paginationPageSize={10}
              animateRows={true}
              components={frameworkComponents}
              rowHeight={29}
              headerHeight={32}
            ></AgGridReact>
          </div>
        </Grow>
      </GridLoadingSpinner>
    </Fragment>
  );
}

export default QALog;
