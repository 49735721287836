import React, { useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-enterprise";
import { AgGridClasses } from "@genre/g2common-theme";
import { CashLogGridType, CashLogProps } from "./cashLogUtils";
import { delegatedAxios } from "../../../services/axios";
import {
  comparatorForDate,
  CustomSnackbar,
  DeleteChildRenderer,
  formatDateForGrid,
  handleErrorResponse,
  ModalErrors,
  showInvalidSnackbar,
  SimpleBackdrop,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";
import {
  GridReadyEvent,
  ICellRendererParams,
  GridApi,
  CellClickedEvent,
} from "ag-grid-community";
import { DetailSpan } from "@genrecp/g2clientportal-genbill";
import { Grid } from "@mui/material";
import { AgGridReact as AgGridReactType } from "ag-grid-react/lib/agGridReact";

interface Props extends CashLogProps {}

function CashLogGrid({ request, dispatch }: Props) {
  const gridRef = useRef<AgGridReactType>(null);
  const { enqueueSnackbar } = useRibbonSnackbar();
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const [loader, setLoader] = React.useState<boolean>(false);

  const [colDefs] = useState<ColDef<CashLogGridType>[]>([
    {
      field: "createdDate",
      filter: "agDateColumnFilter",
      sort: "desc",
      valueFormatter: function (params: any) {
        return formatDateForGrid(params.data.createdDate);
      },
      filterParams: {
        filterOptions: ["equals"],
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue, true);
        },
        suppressAndOrCondition: true,
      },
    },
    {
      field: "cashLogDate",
      filter: "agDateColumnFilter",
      valueFormatter: function (params: any) {
        return formatDateForGrid(params.data.cashLogDate);
      },
      filterParams: {
        filterOptions: ["equals"],
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue, true);
        },
        suppressAndOrCondition: true,
      },
    },
    {
      field: "tatStartDate",
      headerName: "TAT Start Date",
      filter: "agDateColumnFilter",
      valueFormatter: function (params: any) {
        return formatDateForGrid(params.data.tatStartDate);
      },
      filterParams: {
        filterOptions: ["equals"],
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return comparatorForDate(filterLocalDateAtMidnight, cellValue, true);
        },
        suppressAndOrCondition: true,
      },
    },
    {
      field: "g2CompanyCode",
      colId: "g2CompanyCode",
      headerName: "Company",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["General Star", "Genesis"],
        suppressAndOrCondition: true,
      },
      valueGetter: function (params: any) {
        const { g2CompanyCode } = params?.data || {};
        return g2CompanyCode === "GSI"
          ? "General Star"
          : g2CompanyCode === "GIC"
          ? "Genesis"
          : "";
      },
    },
    {
      field: "username",
      colId: "username",
      headerName: "Created By",
      filter: "agTextColumnFilter",
    },
    {
      flex: 0.5,
      cellRenderer: function (params: ICellRendererParams<any>) {
        return (
          <DetailSpan
            onClick={() => {
              dispatch({
                type: "UPDATE_UNIVERSAL_REQUEST",
                request: {
                  gridSelector: "DETAIL",
                  cashLogID: params.data.cashLogID,
                },
              });
            }}
          >
            Details
          </DetailSpan>
        );
      },
    },
    {
      headerName: "",
      onCellClicked: onDeleteRowSelected,
      cellRenderer: "DeleteChildRenderer",
      flex: 0.3,
    },
  ]);

  const frameworkComponents = {
    DeleteChildRenderer: DeleteChildRenderer,
  };

  function onDeleteRowSelected(params: CellClickedEvent<CashLogGridType>) {
    const { cashLogID: id } = params.data || {};
    if (!id) {
      showInvalidSnackbar(null, enqueueSnackbar);
      return;
    }
    setLoader(true);
    delegatedAxios
      .delete(`/CashLogs/Delete/${id}`)
      .then((res: any) => {
        setLoader(false);

        const { status } = res || {};
        if (status === 200) {
          gridRef.current?.api && getData(gridRef.current?.api);
        }
      })
      .catch((e: any) => {
        setLoader(false);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const defaultColDef: ColDef = {
    cellClass: "cell-wrap-text",
    cellStyle: { whiteSpace: "normal" },
    sortable: true,
    resizable: true,
    floatingFilter: true,
    flex: 1,
  };

  function getData(params: GridApi) {
    delegatedAxios
      .get("/CashLogs")
      .then((res: any) => {
        const { status } = res || {};
        if (status === 200) {
          params?.setRowData(res?.data || []);
        } else params?.setRowData([]);
      })
      .catch((e: any) => {
        params?.setRowData([]);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const onGridReady = (params: GridReadyEvent) => {
    getData(params.api);
  };

  return (
    <Grid md={12} item>
      <div
        style={{ width: "100%", height: 530 }}
        className={`ag-theme-alpine ${AgGridClasses["ag-theme-alpine"]}`}
      >
        <SimpleBackdrop open={loader} />
        <CustomSnackbar request={modalErrors} setRequest={setModalErrors} />
        <AgGridReact
          rowHeight={29}
          headerHeight={32}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          columnDefs={colDefs}
          pagination={true}
          cacheBlockSize={10}
          paginationPageSize={10}
          animateRows={true}
          components={frameworkComponents}
          ref={gridRef}
        ></AgGridReact>
      </div>
    </Grid>
  );
}

export default CashLogGrid;
