import React from "react";
import { CashLogProps } from "./cashLogUtils";
import {
  handleErrorResponse,
  handleSuccessResponse,
  ModalErrors,
  showInvalidSnackbar,
  useRibbonSnackbar,
} from "@genrecp/g2clientportal-common";

import {
  delegatedAxios,
  formDataDelegatedAxios,
} from "../../../services/axios";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

import AddCashLogForm from "./AddCashLogForm";

interface Props extends CashLogProps {}

function AddCashLog({ request, dispatch }: Props) {
  const { enqueueSnackbar } = useRibbonSnackbar();
  const [modalErrors, setModalErrors] = React.useState<ModalErrors>({
    errors: { messages: [] },
    ErrorModelOpen: false,
  });
  const [loader, setLoader] = React.useState<boolean>(false);
  const createdBy = useSelector((state: RootState) => state?.auth?.currentUser);

  React.useEffect(() => {
    if (request.gridSelector === "DETAIL") getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.gridSelector]);

  function getData(id: string | undefined = undefined) {
    setLoader(true);
    delegatedAxios
      .get(`/CashLogs/Get/${id ? id : request.cashLogID}`)
      .then((res: any) => {
        const { status, data } = res || {};
        setLoader(false);
        if (status === 200 && data) {
          const {
            g2CompanyCode,
            tatStartDate,
            cashLogDate,
            createdBy,
            createdDate,
            modifiedBy,
            modifiedDate,
            cashLogDocuments,
            comment,
          } = data || {};

          dispatch({
            type: "UPDATE_UNIVERSAL_REQUEST",
            request: {
              g2CompanyCode,
              cashLogDate,
              tatStartDate,
              createdBy,
              createdDate,
              comment: comment ? comment : "",
              files: cashLogDocuments || [],
              modifiedBy,
              modifiedDate,
            },
          });
        }
      })
      .catch((e: any) => {
        setLoader(false);
        const modalErrorMessages = handleErrorResponse(e);
        setModalErrors({
          ...modalErrors,
          errors: modalErrorMessages,
          ErrorModelOpen: true,
        });
      });
  }

  const handleSubmit = async () => {
    try {
      if (!request.cashLogDate) {
        showInvalidSnackbar(
          null,
          enqueueSnackbar,
          "Cash Log Date is required!"
        );
        return;
      }

      if (!request.tatStartDate) {
        showInvalidSnackbar(
          null,
          enqueueSnackbar,
          "TAT Start Date is required!"
        );
        return;
      }

      setLoader(true);

      const formData = new FormData();
      const newDate = new Date().toISOString();

      request.files.length &&
        request.files
          .filter((file: any) => file instanceof File)
          .map((filteredFile: any) => formData.append("files", filteredFile));

      request.tatStartDate &&
        formData.append("tatStartDate", request.tatStartDate);
      request.cashLogDate &&
        formData.append("cashLogDate", request.cashLogDate);

      formData.append("g2CompanyCode", request.g2CompanyCode);
      formData.append("comment", request.comment);

      if (request.cashLogID) {
        formData.append("cashLogID", request.cashLogID);
        formData.append("modifiedBy", createdBy!.id!);
        formData.append("modifiedDate", newDate);
        formData.append("createdBy", request.createdBy!);
        formData.append("createdDate", request.createdDate!);
      } else {
        formData.append("createdBy", createdBy!.id!);
        formData.append("createdDate", newDate);
      }

      const response: any = await formDataDelegatedAxios.post(
        `/CashLogs/Create`,
        formData
      );

      setLoader(false);

      const { status, messages, data } = response || {};

      if (status === 200) {
        handleSuccessResponse(response, enqueueSnackbar);
        getData(data);
        dispatch({
          type: "UPDATE_UNIVERSAL_REQUEST",
          request: {
            cashLogID: data,
          },
        });
      } else if (status === 401) return;
      else {
        console.log(messages);
        enqueueSnackbar(`Error! Invalid Request`, {
          variant: "error",
        });
      }
    } catch (e: any) {
      setLoader(false);
      const modalErrorMessages = handleErrorResponse(e);
      setModalErrors({
        ...modalErrors,
        errors: modalErrorMessages,
        ErrorModelOpen: true,
      });
    }
  };

  return (
    <AddCashLogForm
      request={request}
      dispatch={dispatch}
      modalErrors={modalErrors}
      setModalErrors={setModalErrors}
      loader={loader}
      setLoader={setLoader}
      handleSubmit={handleSubmit}
      getData={getData}
    />
  );
}

export default AddCashLog;
