import {
  CashTrackerFormStateType,
  SuspenseStateType,
} from "../CashTrackerUtils";

export interface ClientInfosResponseType {
  billpointCode: string;
  branchName: string;
  g2CompanyCode: string;
  grapCode: string;
  mgAorBroker: string;
  operationsContact: string;
}

export interface CreateCashTrackerPayloadType {
  clientInfoID: string;
  checkNumber: string;
  checkDate: string;
  lockBoxDate: string;
  totalCheckAmount: number;
  cashIssueStatusTypeID: number;
  createdDate: string;
  createdBy: string;
}

export interface UpdateCashTrackerPayloadType {
  CashTrackerID: string;
  clientInfoID: string;
  checkNumber: string;
  checkDate: string;
  lockBoxDate: string;
  totalCheckAmount: number;
  cashIssueStatusTypeID: number;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}
export interface GetCashTrackerRecordResponseType {
  cashTrackerID: string;
  clientInfoID: string;
  checkNumber: string;
  checkDate: string;
  lockBoxDate: string;
  totalCheckAmount: number;
  cashIssueStatusTypeID: number;
  createdDate: string;
  createdBy: string;
  modifiedDate: null;
  modifiedBy: null;
}

export interface CashTrackerGridType {
  cashTrackerID: string;
  clientInfoID: null;
  client: string;
  checkNumber: string;
  checkDate: string;
  lockBoxDate: string;
  totalCheckAmount: number;
  cashIssueStatusTypeID: number;
  cashTrackerStatus: string;
  clientLocationID: string;
  billpointCode: string;
  suspenseID: string;
  suspenseTypeID: number;
  suspenseType: null;
  suspenseReasonTypeID: number;
  suspenseReasonType: null;
  policyNumber: null;
  insuredName: null;
  grossPremium: number;
  suspendedAmount: number;
  operationsContact: null;
  createdDate: string;
  createdBy: null;
  modifiedDate: null;
  modifiedBy: null;
  branchName: string;
  g2CompanyCode: string;
  mgAorBroker: string;
}

export interface CreateSuspenseFormPayloadType {
  suspenseID?: string;
  cashTrackerID: string;
  policyNumber: string;
  insuredName: string;
  grossPremium: number;
  suspendedAmount: number;
  suspenseReasonTypeID: number;
  suspenseTypeID: 1 | 2;
  endorsementNumber?: number;
  additionalInfo: string;
  createdDate: string;
  createdBy: string;
  cashIssueStatusTypeID: number;
}

export interface UpdateSuspenseFormPayloadType {
  suspenseID: string;
  cashTrackerID: string;
  policyNumber: string;
  insuredName: string;
  grossPremium: number;
  suspendedAmount: number;
  suspenseReasonTypeID: number;
  suspenseTypeID: number;
  endorsementNumber?: number;
  additionalInfo: string;
  createdDate?: string | null;
  createdBy?: string | null;
  modifiedDate: string | null;
  modifiedBy: string | null;
  cashIssueStatusTypeID: number;
}

export interface CashTrackerStatusFilterType {
  cashIssueStatusTypeID: number;
  cashIssueStatusTypeText: string;
  active: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

export const validateCashTrackerRecord = (
  cashTrackerFormState: CashTrackerFormStateType
) => {
  let fieldName = "";
  let isValid = true;
  const validateObject = {
    isValid,
    fieldName,
  };

  if (!cashTrackerFormState.clientInfo) {
    validateObject.isValid = false;
    validateObject.fieldName = "Client Name";
    return validateObject;
  }

  if (!cashTrackerFormState.checkNumber) {
    validateObject.isValid = false;
    validateObject.fieldName = "Check Number";
    return validateObject;
  }

  if (!cashTrackerFormState.checkAmount) {
    validateObject.isValid = false;
    validateObject.fieldName = "Check Amount";
    return validateObject;
  }

  if (!cashTrackerFormState.checkDate) {
    validateObject.isValid = false;
    validateObject.fieldName = "Check Date";
    return validateObject;
  }

  if (!cashTrackerFormState.lockBoxDate) {
    validateObject.isValid = false;
    validateObject.fieldName = "lock Box Date";
    return validateObject;
  }

  if (!cashTrackerFormState.cashTrackerStatus) {
    validateObject.isValid = false;
    validateObject.fieldName = "Cash Tracker Status";
    return validateObject;
  }

  return validateObject;
};

export const validateSuspenseRecord = (
  suspenseFormState: SuspenseStateType
) => {
  let fieldName = "";
  let isValid = true;
  const validateObject = {
    isValid,
    fieldName,
  };

  if (!suspenseFormState.policyNumber) {
    validateObject.isValid = false;
    validateObject.fieldName = "Policy Number";
    return validateObject;
  }

  if (!suspenseFormState.insuredName) {
    validateObject.isValid = false;
    validateObject.fieldName = "Insured Name";
    return validateObject;
  }

  if (!suspenseFormState.suspendedAmount) {
    validateObject.isValid = false;
    validateObject.fieldName = "Suspended Amount";
    return validateObject;
  }

  if (!suspenseFormState.grossPremium) {
    validateObject.isValid = false;
    validateObject.fieldName = "Gross Premium Amount";
    return validateObject;
  }

  if (!suspenseFormState.suspenseReason) {
    validateObject.isValid = false;
    validateObject.fieldName = "Suspense Reason";
    return validateObject;
  }

  return validateObject;
};

export const validateAmountLimit = (value: string) => {
  const val = typeof value === "number" ? (value as number).toString() : value;
  const convertedVal = Number(val.replace(/[^0-9.-]+/g, ""));
  if (convertedVal > 999999999999.99) {
    return true;
  }
  if (convertedVal < -999999999999.99) {
    return true;
  }
  return false;
};
